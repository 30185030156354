import React from 'react'

import PropTypes from 'prop-types'

import './navigation-links.css'

const NavigationLinks = (props) => {
  return (
    <nav className={`navigation-links-nav ${props.rootClassName} `}>
      <a
        href="https://tapringer.com/#featuresanchor"
        className="navigation-links-link navbar-link"
      >
        {props.text2}
      </a>
      <a
        href="https://tapringer.com/#footeranchor"
        className="navigation-links-link1 navbar-link"
      >
        {props.text4}
      </a>
    </nav>
  )
}

NavigationLinks.defaultProps = {
  text2: 'Features',
  rootClassName: '',
  text4: 'Contacts',
}

NavigationLinks.propTypes = {
  text2: PropTypes.string,
  rootClassName: PropTypes.string,
  text4: PropTypes.string,
}

export default NavigationLinks

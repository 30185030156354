import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import './privacy-policy-page.css'

const PrivacyPolicyPage = (props) => {
  return (
    <div className="privacy-policy-page-container">
      <Helmet>
        <title>Privacy Policy - TapRinger</title>
        <meta
          name="description"
          content="TapRinger is the free softphone to make VoIP calls, messaging and buy phone numbers from 100 countries at lowest rates. Available for iPhone and Android."
        />
        <meta property="og:title" content="Privacy Policy - TapRinger" />
        <meta
          property="og:description"
          content="TapRinger is the free softphone to make VoIP calls, messaging and buy phone numbers from 100 countries at lowest rates. Available for iPhone and Android."
        />
      </Helmet>
      <Navbar
        image_src="4ac0af6d-f893-4673-b691-c2925d3c1300"
        image_src1="4ac0af6d-f893-4673-b691-c2925d3c1300"
      ></Navbar>
      <main className="privacy-policy-page-main">
        <div className="section-container">
          <span className="privacy-policy-page-text">
            TapRinger Privacy Policy
          </span>
          <div className="privacy-policy-page-container01"></div>
          <div className="privacy-policy-page-container02">
            <span className="privacy-policy-page-text001">1. Introduction</span>
            <div className="privacy-policy-page-container03"></div>
            <span className="privacy-policy-page-text002">
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                This Privacy Policy sets out how we, DelaySoft OÜ (“TapRinger”),
                use and protect your personal data that you provide to us, or
                that is otherwise obtained or generated by us, in connection
                with your use of our cloud-based messaging services (the
                “Services”). For the purposes of this Privacy Policy, ‘we’, ‘us’
                and ‘our’ refers to TapRinger, and ‘you’ refers to you, the user
                of the Services.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-policy-page-text007">
                1.1. Privacy Principles
              </span>
              <br></br>
              <br></br>
              <span>
                TapRinger has two fundamental principles when it comes to
                collecting and processing private data:
              </span>
              <br></br>
              <span>- We don&apos;t use your data to show you ads.</span>
              <br></br>
              <span>
                - We only store the data that TapRinger needs to function as a
                secure and feature-rich messaging service.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-policy-page-text017">
                1.2. Terms of Service
              </span>
              <br></br>
              <span>
                This Privacy Policy forms part of our Terms of Service, which
                describes the terms under which you use our Services and which
                are available at https://tapringer.com/termsofuse. This Privacy
                Policy should therefore be read in conjunction with those terms.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-policy-page-text022">
                1.3. Table of Contents
              </span>
              <br></br>
              <span>- the legal basis for processing your personal data</span>
              <br></br>
              <span>- what personal data we may collect from you</span>
              <br></br>
              <span>- how we keep your personal data safe</span>
              <br></br>
              <span>- what we may use your personal data for</span>
              <br></br>
              <span>- who your personal data may be shared with</span>
              <br></br>
              <span>- your rights regarding your personal data</span>
              <br></br>
              <br></br>
            </span>
            <div className="privacy-policy-page-container04"></div>
            <span className="privacy-policy-page-text037">
              2. Legal Ground for Processing Your Personal Data
            </span>
            <div className="privacy-policy-page-container05"></div>
            <span className="privacy-policy-page-text038">
              We process your personal data on the ground that such processing
              is necessary to further our legitimate interests (including: (1)
              providing effective and innovative Services to our users; and (2)
              to detect, prevent or otherwise address fraud or security issues
              in respect of our provision of Services), unless those interests
              are overridden by your interest or fundamental rights and freedoms
              that require protections of personal data.
            </span>
            <div className="privacy-policy-page-container06"></div>
            <span className="privacy-policy-page-text039">
              3. What Personal Data We Use
            </span>
            <div className="privacy-policy-page-container07"></div>
            <span className="privacy-policy-page-text040"></span>
            <span className="privacy-policy-page-text041">
              <span className="privacy-policy-page-text042">
                3.1. Basic Account Data
              </span>
              <br className="privacy-policy-page-text043"></br>
              <br></br>
              <span>
                TapRinger is a communication service. You provide your mobile
                number to create a TapRinger account.
              </span>
              <br className="privacy-policy-page-text046"></br>
              <span>
                We don&apos;t want to know your real name, gender, age or what
                you like.
              </span>
              <br className="privacy-policy-page-text048"></br>
              <span>
                Note that users who have you in their contacts will see if you
                are registered in TapRinger.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-policy-page-text052">
                3.2. Phone Number and Contacts
              </span>
              <br className="privacy-policy-page-text053"></br>
              <br></br>
              <span>
                TapRinger uses phone numbers as unique identifiers so that it is
                easy for you to switch from SMS and other messaging apps and
                retain your social graph. We ask your permission before
                accessing your contacts.
              </span>
              <br className="privacy-policy-page-text056"></br>
              <span>
                We store your up-to-date contacts in order to notify you as soon
                as one of your contacts signs up for Telegram and to properly
                display names in notifications. We only need the number and name
                (first and last) for this to work and store no other data about
                your contacts.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-policy-page-text060">
                3.3. Location Data
              </span>
              <br></br>
              <br></br>
              <span>
                We do not collect and use your location data. But we may ask
                permission to access location services in order to allow to
                work  Bluetooth - a common pairing technology used by
                accessories like headphones, wearables, and smart home devices -
                is used to scan and “locate” the accessory.
              </span>
              <br></br>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="privacy-policy-page-text067">
                3.4. Camera and photo gallery
              </span>
              <br></br>
              <br></br>
              <span>
                Accessing your camera and / or photo gallery if you choose to
                share photos or media with your TapRinger contacts. Photos and
                media that you share in your personal chats are end-to-end
                encrypted. We ask your permission before accessing your your
                camera and photo gallery.
              </span>
              <br></br>
            </span>
            <div className="privacy-policy-page-container08"></div>
            <span className="privacy-policy-page-text072">
              4. Keeping Your Personal Data Safe
            </span>
            <div className="privacy-policy-page-container09"></div>
            <span className="privacy-policy-page-text073">
              <span className="privacy-policy-page-text074">
                4.1. End-to-End Encrypted Data
              </span>
              <br className="privacy-policy-page-text075"></br>
              <br></br>
              <span>
                End-to-end encryption means that your calls, messages, media
                within messages including content like images, audio, video,
                documents and files are encrypted to protect against third
                parties from seeing that content.
              </span>
              <br className="privacy-policy-page-text078"></br>
              <span className="privacy-policy-page-text079"> </span>
              <br className="privacy-policy-page-text080"></br>
              <span className="privacy-policy-page-text081">
                4.2. Retention
              </span>
              <br className="privacy-policy-page-text082"></br>
              <br></br>
              <span>
                Unless stated otherwise in this Privacy Policy, the personal
                data that you provide us will only be stored for as long as it
                is necessary for us to fulfill our obligations in respect of the
                provision of the Services.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-policy-page-text087">
                4.3. No Ads Based on User Data
              </span>
              <br></br>
              <span>
                Unlike other services, we don&apos;t use your data for ad
                targeting or other commercial purposes. TapRinger only stores
                the information it needs to function as a secure and
                feature-rich cloud service.
              </span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <div className="privacy-policy-page-container10"></div>
            <span className="privacy-policy-page-text092">
              5. Third Party Payment Services
            </span>
            <div className="privacy-policy-page-container11"></div>
            <span className="privacy-policy-page-text093">
              <span className="privacy-policy-page-text094">
                5.1. Payment Information
              </span>
              <br className="privacy-policy-page-text095"></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                TapRinger does not process payments from users and instead
                relies on different payment providers around the world. It is
                the payment providers that handle and store your credit card
                details. TapRinger does not have access to this information.
                Although we work with payment providers they are completely
                independent from TapRinger. Please study their relevant privacy
                policies before making your data available to them.
              </span>
              <br className="privacy-policy-page-text099"></br>
              <br></br>
              <span className="privacy-policy-page-text101">
                5.2. Credit Card Information
              </span>
              <br className="privacy-policy-page-text102"></br>
              <br></br>
              <span>
                When making a purchase, you enter your credit card details into
                a form supplied by the payment provider that will be processing
                the payment, and this information goes directly to the payment
                provider&apos;s server. Your credit card information never
                reaches TapRinger&apos;s servers. We do not access and do not
                store your credit card information. When you save your credit
                card info, it is saved on the respective payment provider&apos;s
                servers and the payment provider gives TapRinger a token that
                you can reuse for future payments. It is not possible to
                reconstruct your credit card info from the token.
              </span>
              <br className="privacy-policy-page-text105"></br>
              <br></br>
              <span className="privacy-policy-page-text107">
                5.3. Payment Refund
              </span>
              <br className="privacy-policy-page-text108"></br>
              <br></br>
              <span>
                User has the right on refund from account balance. At that,
                amount for refund should not exceed current amount on account
                balance. Refund is impossible for services actually rendered.
                Also refund is impossible for one-time services actually
                rendered (for example number connection), payment for which is
                taken from account balance at that time. Email
                address support@tapringer.com can be used to ask questions.
              </span>
            </span>
            <div className="privacy-policy-page-container12"></div>
            <span className="privacy-policy-page-text111">
              6. Who Your Personal Data May Be Shared With
            </span>
            <div className="privacy-policy-page-container13"></div>
            <span className="privacy-policy-page-text112">
              We do not share your personal data.
            </span>
            <div className="privacy-policy-page-container14"></div>
            <span className="privacy-policy-page-text113">
              7. Your Rights Regarding the Personal Data You Provide to Us
            </span>
            <div className="privacy-policy-page-container15"></div>
            <span className="privacy-policy-page-text114">
              <span className="privacy-policy-page-text115">
                7.1. Your Rights
              </span>
              <br className="privacy-policy-page-text116"></br>
              <br></br>
              <span>
                Under applicable data protection legislation, in certain
                circumstances, you have rights concerning your personal data.
                You have a right to: (1) request a copy of all your personal
                data that we store and to transmit that copy to another data
                controller; (2) delete or amend your personal data; (3)
                restrict, or object to, the processing of your personal data;
                (4) correct any inaccurate or incomplete personal data we hold
                on you; and (5) lodge a complaint with national data protection
                authorities regarding our processing of your personal data.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-policy-page-text121">
                7.2 Exercising Your Rights
              </span>
              <br className="privacy-policy-page-text122"></br>
              <br></br>
              <span>
                If you wish to exercise any of these rights, kindly contact us
                at support@tapringer.com.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-policy-page-text127">
                7.3 Data Settings
              </span>
              <br className="privacy-policy-page-text128"></br>
              <br></br>
              <span>
                Sadly, if you&apos;re not OK with TapRinger&apos;s modest
                minimum requirements, it won&apos;t be possible for us to
                provide you with our Services.
              </span>
              <br></br>
            </span>
            <div className="privacy-policy-page-container16"></div>
            <span className="privacy-policy-page-text132">8. Cookies</span>
            <div className="privacy-policy-page-container17"></div>
            <span className="privacy-policy-page-text133">
              <span>
                We do not use cookies in TapRinger application. We use cookies
                on TapRinger web site https://tapringer.com
              </span>
              <br></br>
              <span>
                In order to improve our Website and ensure the best possible
                service provided on a computer or mobile device, as well as to
                provide you with quick and easy navigation on our website, we
                process data such as IP address, information about your browser
                and operating system, data from Cookies and other similar
                information.
              </span>
              <span>
                Such data is transmitted by your device, which you use to open
                our website, and are automatically used by us exclusively for
                statistical purposes, to analyze ways to improve the quality of
                the services provided by TapRinger app.
              </span>
            </span>
            <Link to="/cookiespolicy" className="privacy-policy-page-navlink">
              Learn more about Cookies Policy
            </Link>
            <div className="privacy-policy-page-container18"></div>
            <span className="privacy-policy-page-text138">
              9. Changes to this Privacy Policy
            </span>
            <div className="privacy-policy-page-container19"></div>
            <span className="privacy-policy-page-text139">
              We will review and may update this Privacy Policy from time to
              time. Any changes to this Privacy Policy will become effective
              when we post the revised Privacy Policy on this page
              https://tapringer.com/privacypolicy. Please check our website
              frequently to see any updates or changes to our Privacy Policy, a
              summary of which we will set out below.
            </span>
            <div className="privacy-policy-page-container20"></div>
            <span className="privacy-policy-page-text140">
              10. Questions and concerns
            </span>
            <div className="privacy-policy-page-container21"></div>
            <span className="privacy-policy-page-text141">
              If you have any questions about privacy and our data policies,
              please contact us here support@tapringer.com.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </div>
        </div>
      </main>
      <Footer></Footer>
      <Copyright></Copyright>
    </div>
  )
}

export default PrivacyPolicyPage

import React from 'react'

import PropTypes from 'prop-types'

import UseCookiesBar from './use-cookies-bar'
import './copyright.css'

const Copyright = (props) => {
  return (
    <div className="copyright-copyright">
      <div className="copyright-max-width max-content-container">
        <a
          href="https://delaysoft.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="copyright-image"
          />
        </a>
        <span className="copyright-text">
          <span className="copyright-text1">
            Copyright © 2022 DelaySoft OÜ. All rights reserved.
          </span>
          <span></span>
          <span></span>
        </span>
      </div>
      <UseCookiesBar></UseCookiesBar>
    </div>
  )
}

Copyright.defaultProps = {
  image_alt: 'image',
  image_src: '/playground_assets/delaysoft-logo.svg',
}

Copyright.propTypes = {
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
}

export default Copyright

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import CookiesPolicyPage from './views/cookies-policy-page'
import Home from './views/home'
import TermsOsUsePage from './views/terms-os-use-page'
import PrivacyPolicyPage from './views/privacy-policy-page'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={CookiesPolicyPage} exact path="/cookiespolicy" />
        <Route component={Home} exact path="/" />
        <Route component={TermsOsUsePage} exact path="/termsofuse" />
        <Route component={PrivacyPolicyPage} exact path="/privacypolicy" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))

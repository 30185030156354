import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import './cookies-policy-page.css'

const CookiesPolicyPage = (props) => {
  return (
    <div className="cookies-policy-page-container">
      <Helmet>
        <title>Cookies Policy - TapRinger</title>
        <meta
          name="description"
          content="TapRinger is the free softphone to make VoIP calls, messaging and buy phone numbers from 100 countries at lowest rates. Available for iPhone and Android."
        />
        <meta property="og:title" content="Cookies Policy - TapRinger" />
        <meta
          property="og:description"
          content="TapRinger is the free softphone to make VoIP calls, messaging and buy phone numbers from 100 countries at lowest rates. Available for iPhone and Android."
        />
      </Helmet>
      <Navbar
        image_src="4ac0af6d-f893-4673-b691-c2925d3c1300"
        image_src1="4ac0af6d-f893-4673-b691-c2925d3c1300"
      ></Navbar>
      <main className="cookies-policy-page-main">
        <div className="section-container">
          <span className="cookies-policy-page-text">Cookies Policy</span>
          <div className="cookies-policy-page-container01"></div>
          <div className="cookies-policy-page-container02">
            <span className="cookies-policy-page-text01">
              <span>
                Transparency and security in processing your data is a priority
                for DelaySoft OÜ. This Cookie Policy (further – Policy) includes
                goals and the process of cookies collection and processing. This
                Cookie Policy applies to any product or service provided by
                DelaySoft OÜ which have a link to this Policy or incorporates it
                by reference.
              </span>
              <br></br>
              <span>
                On TapRinger and DelaySoft OÜ websites we use cookies for users’
                convenience when working with website content and for receiving
                website visit statistics. Please note, cookies do not include
                personal data.
              </span>
            </span>
            <div className="cookies-policy-page-container03"></div>
            <span className="cookies-policy-page-text04">Definition</span>
            <div className="cookies-policy-page-container04"></div>
            <span className="cookies-policy-page-text05">
              <span>
                Cookies are small files that are stored in the web browser that
                are used by all visitors of our website to be recognized by the
                server during the following visits. This information does not
                reveal your identity or any personal information, does not
                provide access to any data on your device. Cookies allow our
                system to identify you as a certain user who has visited the
                website before, went through certain pages, etc. They also allow
                the system to save your preferences and such technical
                information as visits statistics or data on visited pages in a
                period of time. The main task of Cookies is to provide the User
                with fast access to chosen Services.
              </span>
              <span></span>
              <br></br>
              <span></span>
              <span></span>
            </span>
            <span className="cookies-policy-page-text10"></span>
            <div className="cookies-policy-page-container05"></div>
            <span className="cookies-policy-page-text11">
              What types of Cookies do we collect?
            </span>
            <div className="cookies-policy-page-container06"></div>
            <span className="cookies-policy-page-text12">
              <span>
                TapRinger and DelaySoft OÜ websites use session and persistent
                cookies. Persistent cookies are stored at the web-browser and
                are active until the end of a set period of time specified in
                the cookies unless the user deletes them sooner. Session
                cookies, on the contrary, expire after a short period of time,
                or when you close your web browser after using our website.
              </span>
              <br></br>
              <span>
                Below we are describing in detail cookies that can be installed
                from our website. Depending on your activity on the website, you
                can install all or some of them. Please note that the names of
                cookies, pixels and other technologies can be changed in time.
              </span>
            </span>
            <div className="cookies-policy-page-container07">
              <div className="cookies-policy-page-container08">
                <span>
                  <span>Google Analytics</span>
                </span>
              </div>
              <div className="cookies-policy-page-container09">
                <div className="cookies-policy-page-container10">
                  <span>_ga (duration 2 years)</span>
                  <span>_gid (duration 1 day)</span>
                  <span>_gat_xxxxx (duration 1 minute)</span>
                </div>
              </div>
            </div>
            <div className="cookies-policy-page-container11"></div>
            <span className="cookies-policy-page-text20">
              Disabling Cookies option. Deleting Cookies
            </span>
            <div className="cookies-policy-page-container12"></div>
            <span className="cookies-policy-page-text21">
              <span>
                If you are not satisfied with cookies usage, or you want to
                disable saving certain types of cookies, you can change your
                browser settings or delete existing cookies and forbid new
                cookies installation.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                Informational cookie banner appearing on some of our websites
                will request cookies collection. If agreement is not received,
                your device will not be tracked for marketing purposes. You can
                change cookies settings at any time.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                You can find the information regarding popular browser settings
                in the following ways:
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                - Internet Explorer: Menu → Internet Options → Privacy. For
                further information you can contact Microsoft support or Help
                Center in the browser.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                - Firefox: Tools → Options → Privacy → History → Use custom
                settings for history. For further information you can contact
                Mozilla support or Help Center in the browser.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                - Chrome: Setting → Show advanced settings → Privacy → Content
                settings. For further information you can contact Google support
                or Help Center in the browser.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                - Safari: Settings -&gt; Privacy. For further information you
                can contact Apple support or Help Center in the browser.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                For setting other browsers check documentation provided by
                certain browser developers.
              </span>
              <br></br>
              <span>
                You can refuse to cookies use by third parties via the refusal
                mechanism on the corresponding Network Advertising Initiative
                page. You can also limit the use of device identifications by
                setting the necessary parameters on a device.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                Please note, if you delete cookies and deny their use, you might
                not be able to utilize all the features of our website and will
                not be able to save settings. Besides, some pages might not be
                displayed correctly.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                You can learn more about cookies on these third-party websites:
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>- wikipedia.org</span>
              <br></br>
              <span>- allaboutcookies.org</span>
              <br></br>
              <span>
                - aboutcookies.org
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <div className="cookies-policy-page-container13"></div>
            <span className="cookies-policy-page-text50">
              Contact information
            </span>
            <div className="cookies-policy-page-container14"></div>
            <span className="cookies-policy-page-text51">
              <span>
                This Cookie Policy can be changed at the discretion of DelaySoft
                OÜ, including but not limited to situations when corresponding
                changes are made to the applicable law, as well as due to
                changes in Website and Services performance.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                When substantive changes are made to this Policy, Users are
                notified via the website, personal account and via email (for
                registered users).
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                If you have any questions or comments regarding this Policy, or
                you want us to update information regarding your preferences,
                please send a message to: support@tapringer.com
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>Latest update date: 18.07.2022</span>
            </span>
          </div>
        </div>
      </main>
      <Footer></Footer>
      <Copyright></Copyright>
    </div>
  )
}

export default CookiesPolicyPage

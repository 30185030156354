import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import './terms-os-use-page.css'

const TermsOsUsePage = (props) => {
  return (
    <div className="terms-os-use-page-container">
      <Helmet>
        <title>Terms of Use - TapRinger</title>
        <meta
          name="description"
          content="TapRinger is the free softphone to make VoIP calls, messaging and buy phone numbers from 100 countries at lowest rates. Available for iPhone and Android."
        />
        <meta property="og:title" content="Terms of Use - TapRinger" />
        <meta
          property="og:description"
          content="TapRinger is the free softphone to make VoIP calls, messaging and buy phone numbers from 100 countries at lowest rates. Available for iPhone and Android."
        />
      </Helmet>
      <Navbar
        image_src="4ac0af6d-f893-4673-b691-c2925d3c1300"
        image_src1="4ac0af6d-f893-4673-b691-c2925d3c1300"
      ></Navbar>
      <main className="terms-os-use-page-main">
        <div className="section-container">
          <span className="terms-os-use-page-text">
            <span className="terms-os-use-page-text001">Terms of Use</span>
            <span></span>
          </span>
          <div className="terms-os-use-page-container01"></div>
          <div className="terms-os-use-page-container02">
            <span className="terms-os-use-page-text003">
              <span className="terms-os-use-page-text004">
                To get the right to use TapRinger app services You have to
                accept the terms and conditions of a given Treaty and click on
                “Registration”. Given Terms and Conditions extend on all
                Services rendered under the Treaty, concluded between
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="terms-os-use-page-text005">
                DelaySoft OÜ and You as a user. TapRinger app services from
                DelaySoft OÜ side can be provided by operators listed under
                Definitions. Besides that, the fact of using Your User Account
                and TapRinger app services means that You are agreed with terms
                and conditions, contained either in this document or in its
                following revisions.
              </span>
              <br></br>
              <span className="terms-os-use-page-text006"></span>
              <br></br>
              <span className="terms-os-use-page-text007">
                Electronic signatures and treaty (-ies). You confirm and agree
                with that by clicking on “I accept Terms of Use,“ You become a
                party to a treaty, having in possession binding legal effect.
                Hereby You express Your acceptance on using electronic means
                with the intent for concluding contracts, placing orders and
                other data transfer, and also for electronic delivery of
                notifications, regulatory documents and operations data, started
                or ended with the using of TapRinger app services. Besides that
                hereby You disclaim, within the ambit of affirmatively
                authorized by the correspondent provisions of effective
                legislation, from any rights and demands, guaranteed under the
                laws and regulations of any jurisdiction, demanding the presence
                of personal (not electronic) signature and physical way of
                delivery and keeping of documents, not available in electronic
                form.
              </span>
              <br></br>
              <span className="terms-os-use-page-text008"></span>
              <br></br>
              <span className="terms-os-use-page-text009">
                Emergency calling is forbidden. TapRinger app services are not
                supposed for emergency telephone call support and can not be
                used for those purposes. You understand and agree with that,
                accepting terms and conditions of that treaty.
              </span>
              <br></br>
              <span className="terms-os-use-page-text010"></span>
              <br></br>
              <span className="terms-os-use-page-text011">
                Jurisdictional restriction. You have the right to use TapRinger
                app in any country if it&apos;s not contrary to the laws of that
                country. If You want to use this Service on the terrene where
                the usage of TapRinger app services are not prescribed by
                legislation or forbidden, You have no right to conclude given
                treaty and to use TapRinger app Services.
              </span>
              <br></br>
              <span className="terms-os-use-page-text012"></span>
              <br></br>
              <span className="terms-os-use-page-text013">
                By accepting the terms and conditions of given Treaty You
                confirm that Your usage of TapRinger app services on the terrene
                of Your living is permitted by the legislation. Moreover, if You
                live on the terrene of jurisdiction, the legislation of which
                lay under age limits on the usage of internet-applications or
                concluding treaties, which have a legal effect such as given
                Treaty do, and You are the subject of given jurisdiction who do
                not qualify the demands of age qualifications, You have no right
                to conclude given Treaty and to use TapRinger app services.
              </span>
              <br></br>
              <span className="terms-os-use-page-text014"></span>
              <br></br>
              <span className="terms-os-use-page-text015">
                By accepting the terms of this Agreement, the user confirms that
                he has reached the age of majority, has sufficient capacity and
                legal capacity and is entitled to accept the terms of this
                Agreement.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <div className="terms-os-use-page-container03"></div>
            <span className="terms-os-use-page-text016">1. DEFINITIONS</span>
            <div className="terms-os-use-page-container04"></div>
            <span className="terms-os-use-page-text017">
              <span>
                1.1. Account — Your account from which payments for TapRinger
                app services are made.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.2. Treaty or agreement - a bilateral User Agreement, under
                which the Operator undertakes to provide the User with
                communication services, and the latter undertakes to pay for
                them.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.3. Emergency calling – services on providing user contact with
                representatives of emergency or public security centre.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.4. Commercial user agreement — agreement for commercial users
                concluded with You on usage of DelaySoft OÜ software.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.5. Password — chosen by You code which coupled with Your login
                gives You the right to access Your Account.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.6. Balance on TapRinger account — source to pay by user
                TapRinger app services or other services and goods which can be
                offered by TapRinger app services in future.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.7. TapRinger number — means number, provided to Your to use
                TapRinger app services.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.8. TapRinger software - software distributed by DelaySoft OÜ
                for usage in sphere of IP-telephony - which provide You with
                possibility to use TapRinger app Services, including, among
                other issues, new, upgraded and fixed versions of such software.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.9. Terms of Use — given common regulations and provision which
                are the part of Treaty and cover its contain apart, in which
                form they are provided.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.10. Login — chosen by You identifying code which coupled with
                the Password gives You the right to access Your Account.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.11. Services — total of paid services and program
                possibilities provided by TapRinger app services, and also other
                services which TapRinger app can offer in future.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.12. Website - www.tapringer.com, from which You can download
                TapRinger app software.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.13. You, the User, — real or legal person who is a user of
                TapRinger app services.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                1.14. Operator – one of the listed companies providing services
                according to the User Agreement conditions:
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                DelaySoft OÜ, Registration number: 16444325, Harju maakond,
                Tallinn, Kesklinna linnaosa, F. R. Kreutzwaldi tn 4-7, 10120
              </span>
              <span></span>
              <br></br>
              <span></span>
              <span></span>
            </span>
            <span className="terms-os-use-page-text052"></span>
            <div className="terms-os-use-page-container05"></div>
            <span className="terms-os-use-page-text053">
              2. GENERAL PROVISIONS
            </span>
            <div className="terms-os-use-page-container06"></div>
            <span className="terms-os-use-page-text054">
              <span>
                2.1. TapRinger app services. You understand and agree that
                TapRinger app services are not traditional telephone services
                and not supposed to use as a replacement for traditional
                telephony. Between traditional telephone services and TapRinger
                app Services exist essential differences, some of them, but
                possibly not all, are described in given «Terms of Use».
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                2.1.1. Speech recognition service. Within the concluded contract
                for the provision of communication services, solely for their
                own convenience and in their own interest, TapRinger app Users
                can enable Speech Recognition service that transcribes voice
                data into text. The service is activated in the personal
                account. TapRinger app does not guarantee or claim the
                correctness, accuracy or reliability of the results that can be
                obtained using the Speech Recognition service.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                2.2. Warranty absence. TapRinger app set a goal to provide the
                services with minimal interruptions. Nevertheless, TapRinger app
                can not guarantee constant providing of uninterrupted, in-time
                and free of other limitations Services. You understand that as
                the technology functions on basis of internet-networks and
                public phone lines there is a possibility of appearing power
                failures or Internet interruptions which can lead to
                interruptions in process of Your usage TapRinger app Services,
                such as delay and corruption because of partial loss of
                transferred data. Moreover, You understand that information
                transferred by switched telephone networks of public use are not
                encoded and can be listened by law enforcement agencies or other
                third organizations and persons who use public
                Internet-networks. TapRinger app does not bear responsibility
                for any interruptions, delays, listening and other limitations
                in performance.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                2.3. Control absence. You note and agree with that TapRinger app
                does not possess any information neither about fact(s) of
                information exchange with the usage of TapRinger software nor
                about that information which is transferred in such way and does
                not control this process. Full responsibility for the content of
                information exchange lies on a person who is the source of
                transferred information. Consequently, You can become the
                receiver of information of insulting, indecent or other
                undesired character or harmful for children. DelaySoft OÜ does
                not bear responsibility for the content of any informational
                exchange accomplished with the usage of TapRinger app Services.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                2.4. Services suspense and cancellation. DelaySoft OÜ has the
                right, herewith not to incur civil responsibility, to deny
                Services providing, restrict or set limits of their usage, fully
                or partially suspend or break Services providing not informing
                You about maintenance, modernization and (or) advancing of
                Services, or about any other reason of service breakdown in line
                with article 12.3 of given “Terms of Use”.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                2.5. Technical modifications. DelaySoft OÜ can make changes in
                technical aspects of Services accordingly to changes in consumer
                demand and latest technological developments at its convenience
                and without preliminary informing You about it.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>2.6. Confidentiality and personal data. DelaySoft OÜ</span>
              <span>
                {' '}
                respects all rights and laws in the field of privacy and
                personal data protection, as specified in the Privacy Policy.
              </span>
            </span>
            <div className="terms-os-use-page-container07"></div>
            <span className="terms-os-use-page-text069">
              3. USER RESPONSIBILITIES
            </span>
            <div className="terms-os-use-page-container08"></div>
            <span className="terms-os-use-page-text070">
              <span>
                3.1. Usage of TapRinger app Services. You lie under obligation
                to use the Services in correspondence with given «Terms of Use»,
                agreement for commercial users and requirements of correspondent
                legislation.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                3.2. Information. You lie under obligation to timely provide
                DelaySoft OÜ with any reliable data and information, which
                proves these data, necessary for DelaySoft OÜ, for due
                performance of its obligations on Services providing.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                3.3. Temporary stop in services rendering. If any data, required
                for Services rendering, are not held by DelaySoft OÜ or given
                not in time or contrary to treaty provisions, and also if You
                break Treaty obligations, DelaySoft OÜ has the right to stop
                temporary Services rendering.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                3.4. Legality of activity. You lie under obligation to use
                TapRinger app Services only in legitimate purposes. In this
                content You have no right (a) to intercept or keep track, break
                informational exchange or make changes if You are not side of
                this exchange; (b) spread any mass commercial messages,
                forbidden by acting legislation; (c) use Your Account or
                TapRinger app services rascally; (d) subject any other user to
                materials of insulting, indecent or other undesired character or
                harmful for children and also (e) in other way break the Terms
                of Use of TapRinger app Services.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                3.5. Responsibility limitation. You lie under obligation to
                restrict DelaySoft OÜ from civil responsibility and protect from
                any actionable complaint and actions of third persons, judicial
                settlement and expenses, including, besides all, reasonable
                legal expenses, beared by DelaySoft OÜ as a result of Your
                breaking the Terms of Use, correspondent legislation and rights
                of third persons, and also as a result of incorrect usage of
                TapRinger app Services.
              </span>
            </span>
            <div className="terms-os-use-page-container09"></div>
            <span className="terms-os-use-page-text080">
              4. LOGIN, PASSWORD, ACCOUNT
            </span>
            <div className="terms-os-use-page-container10"></div>
            <span className="terms-os-use-page-text081">
              <span>
                4.1. Login, Password, Account. In order for You to use Services,
                TapRinger app gives Account. You are responsible for Your Login
                and Password Privacy Policy adherence. You are responsible for
                any actions made with the usage of Your Login, Password and
                Account even if these actions were made without Your permission.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                4.2. Notification and responsibility restriction. You lie under
                obligation immediately in writing to inform DelaySoft OÜ about
                any unauthorized and/or fraudulent usage of Your Login, Password
                and Account. DelaySoft OÜ does not bear responsibility for any
                harm as a result of unauthorized and/or fraudulent usage of Your
                Login and password and also unauthorized usage of Your Account.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                4.3. Changing of Login and Password. DelaySoft OÜ retains the
                right of changing Your Login and Password with the intent of
                performance providing or for prevention of fraudulent usage of
                Your Account.
              </span>
            </span>
            <div className="terms-os-use-page-container11"></div>
            <span className="terms-os-use-page-text087">
              5. ACCOUNT BALANCE
            </span>
            <div className="terms-os-use-page-container12"></div>
            <span className="terms-os-use-page-text088">
              5.1. Credit balance. Account balance on TapRinger app is created
              by depositing on Your Account sum of money with the help of
              electronic payment system or other means of payment which can be
              offered by TapRinger app services.
            </span>
            <div className="terms-os-use-page-container13"></div>
            <span className="terms-os-use-page-text089">
              6. CHARGEBACK RULE
            </span>
            <div className="terms-os-use-page-container14"></div>
            <span className="terms-os-use-page-text090">
              <span>
                6.1. Repayable funds. User has the right on refund from account
                balance. At that, amount for refund should not exceed current
                amount on account balance. Refund is impossible for services
                actually renedered. Also refund is impossible for one-time
                services actually rendered (for example number connection),
                payment for which is taken from account balance at that time.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                6.2. Time limitation for refund. Funds on account balance of
                TapRinger app can not be returned upon expiry of 360 days from
                the day of the last funds usage on account balance of TapRinger
                app to pay goods and services. Upon expiry of 360 days these
                funds are considered as lost.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                6.3. Wrong acts. Any wrong acts made by You concerning treaty
                provisions touching chargeback lead to cancellation of given
                Treaty without repayment of fund balance on Your account.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                6.4. Regular payments. The user can switch off the regular
                (recurrent) payment any time in his/her personal account.
              </span>
            </span>
            <div className="terms-os-use-page-container15"></div>
            <span className="terms-os-use-page-text098">
              7. PAYMENTS AND TARIFFS
            </span>
            <div className="terms-os-use-page-container16"></div>
            <span className="terms-os-use-page-text099">
              <span>
                7.1. Payments. For establishment of credit on usage Services You
                have to transfer funds (minimal payment) in favour of DelaySoft
                OÜ, which will be deposited on your Account.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                7.2. Tariffs changing. DelaySoft OÜ reserves the right in any
                time to change tariffs upon condition of preliminary (15
                (fifteen) days) notification about such changing on web-site
                www.tapringer.com or on TapRinger app. The fact of Your further
                usage of Services after launching new tariffs constitutes as
                confirmation of Your agreement with tariff changes.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                7.3. Positive account balance and service extension with
                negative balance. TapRinger app paid services are provided with
                balance above zero and enough funds on your account balance.
                However, some clients can get an automatic advance payment of
                20% of the cost of price plan and number renewal. In this case,
                the account balance becomes negative, but the services within
                the price plan are provided without limitations.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                7.4. Payment processing. TapRinger app services are provided by
                one of the Operators listed under Definitions (item 1.14).
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                7.5. Payer data. DelaySoft OÜ will not sell, purchase, provide,
                exchange or in any other manner disclose Account or Transaction
                data, or personal information of or about a Cardholder to
                anyone, except, it’s Acquirer, Visa/Mastercard Corporations or
                in response to valid government demands.
              </span>
            </span>
            <span className="terms-os-use-page-text109"></span>
            <div className="terms-os-use-page-container17"></div>
            <span className="terms-os-use-page-text110">
              8. RESPONSIBILITY LIMITATION
            </span>
            <div className="terms-os-use-page-container18"></div>
            <span className="terms-os-use-page-text111">
              <span>
                8.1. Absence of responsibility for direct and indirect loss. In
                all degree admissible by correspondent legislation DelaySoft OÜ
                will never bear responsibility for any direct or indirect loss
                (including, among other issues, the loss as a result of the loss
                of profit, release of private and other information,
                interruptions in operation of a business or privacy violation
                and other anyway concerned with use or impossibility of the use
                of Services), even though DelaySoft OÜ was informed about the
                possibility of such occurrence of loss and even though
                undertaken measurements had no desired effect.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                8.2. Special refusal from responsibility in connection with
                emergency calls. TapRinger app Services provided by DelaySoft OÜ
                do not include emergency calls. DelaySoft OÜ does not bear
                responsibility for any loss or damages and whatever complaints;
                and herewith You refuse, under correspondent legislation, from
                any actionable complaints or grounds for bringing of a suit in
                connection with usage of Services for the purpose to call
                members of emergency service. You lie under obligation to
                protect DelaySoft OÜ, under given Service provisions, from
                responsibility and guarantee its protection from any actionable
                complaints and demands for compensation for losses, damages,
                penal sanctions, charging, costs and expenditures (including,
                among other issues, legal charges) suffered by You or third
                persons in connection with Services providing impossibility,
                interruptions or disconnection of services, provided in terms of
                a given document, including any actionable complaints, which are
                the result of TapRinger app incompetency to provide an emergency
                call.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                8.3. Provision slackitude of means for calling emergency. You
                note and agree with that none local or national rules,
                regulations or Statutes do not lay down on DelaySoft OÜ
                liabilities on providing means of emergency calls. Besides all,
                You take into notice that TapRinger app is not aimed for usage
                as a replacement for traditional telephony.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                8.4.Jurisdiction limitations. Inasmuch, that some of
                prohibitions and limitations mentioned above do not possess
                legal effect, such prohibitions and limitations may appear
                invalid towards You. In that case, civil responsibility is
                limited in all degree, set by applicable legislation.
              </span>
            </span>
            <div className="terms-os-use-page-container19"></div>
            <span className="terms-os-use-page-text119">
              9. FORCE-MAJEURE CIRCUMSTANCES
            </span>
            <div className="terms-os-use-page-container20"></div>
            <span className="terms-os-use-page-text120">
              <span>
                9.1. Force-majeure circumstances. You understand and agree that
                in case of an interruption in providing TapRinger app Services,
                which was a result of force-majeure circumstances, DelaySoft OÜ
                does not bear responsibility for breaching its obligations, set
                by given Services Provisions. Force-majeure circumstance is an
                any happening, independent from will of DelaySoft OÜ, including,
                among other issues, natural disasters, acts of civil
                disobedience or civil mess, war or war actions, state of
                emergency of national or local level, power failures, serious
                failures in work of the Internet, floods, earthflows and ground
                subsidence or severe weather patterns.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                9.2. Consequences of force-majeure circumstances. In case of
                force-majeure circumstances, origin DelaySoft OÜ lies under
                obligation without delay according to its lights to inform You
                about supposed delay or time of delay in performance of
                obligations in front of You. DelaySoft OÜ does not bear
                responsibility for the consequences of force-majeure
                circumstances.
              </span>
            </span>
            <div className="terms-os-use-page-container21"></div>
            <span className="terms-os-use-page-text124">
              10. AGREEMENT DURATION AND TERMS OF ITS CANCELLATION
            </span>
            <div className="terms-os-use-page-container22"></div>
            <span className="terms-os-use-page-text125">
              <span>
                10.1. Duration. Treaty comes into effect when You accept its
                terms and conditions and remains valid till its denouncement by
                any of the parties in correspondence with given Services
                provision.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                If the initial self-definition of you as a communication
                services user and the data you provided do not match your actual
                legal status and location, then, in this case, the Agreement for
                the provision of communication services will be considered
                terminated, and you will need to conclude a new contract based
                on your legal status and actual location with the Operator that
                provides services in that location.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                10.2. Termination of Treaty by You. In case of impossibility to
                use TapRinger app, due to the fault of TapRinger app services 10
                days and more, You have the right to terminate given Agreement
                at Your election, upon the condition of preliminary notification
                of Contractor by e-mail within 15 calendar days till the
                supposed date of Agreement termination.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                10.3. Termination of Treaty by DelaySoft OÜ. Without the
                restriction of means of judicial remedies and lesion pay-off
                DelaySoft OÜ can limit, stop or cease services providing and
                Your usage of services and Software of DelaySoft OÜ, prohibit
                access to TapRinger app services, if we consider, that You
                violate given Services provisions, cause problems, possible
                civil liability, violate someone&apos;s intellectual property
                right, involved in fraudulent, immoral or illegal measures or by
                other similar reasons and without the right to appeal our
                measurements by judicial means. DelaySoft OÜ performs such
                cancellation of serviced providing, informing You on Your e-mail
                address, which You gave to us, and (or) termination of Your
                access to Your Account and Services.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                10.4. Effects of Treaty termination. In the case of Treaty
                termination for any reason, You lose all rights of Services use
                and must stop any usage of Services.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                10.5. Preservation of conditions validity. Any conditions of
                Treaty, duration of which due to its essence can not be
                restricted by Agreement duration, remain valid after its
                termination or its expire of validity, including, among other
                issues, all Your complaints, liabilities and warranties of
                responsibility protection.
              </span>
            </span>
            <div className="terms-os-use-page-container23"></div>
            <span className="terms-os-use-page-text137">
              11. OTHER CONDITIONS
            </span>
            <div className="terms-os-use-page-container24"></div>
            <span className="terms-os-use-page-text138">
              <span>
                11.1. Changes in Terms of Use. DelaySoft OÜ reserves the right
                in any time to change present Terms of Use by means of providing
                You with edited Terms of Use or by publication on Web-site of
                revised Treaty. The fact of the following usage of Services
                after publication of new Terms appear to be confirmation of Your
                acceptance with revised terms and conditions. If You refuse to
                accept that revised Terms of Use, You have the right to close
                Your Account, by sending written application on the postal
                address of the company or by e-mail on the e-mail address of
                DelaySoft OÜ
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                11.2. Services Modifications. DelaySoft OÜ reserves the right of
                periodical modification of Services, e.g., to provide compliance
                with the requirements statutory and legislative acts. If You
                refuse to accept that modified Services, You have the right to
                close Your Account.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                11.3. Partial Invalidity. If any condition or provision of a
                given document is rendered ineffective, unenforceable or without
                legal effect (all together or in particular circumstances),
                other provisions of given Terms of Use, nevertheless, must be
                considered as having full legal effect.
              </span>
            </span>
            <div className="terms-os-use-page-container25"></div>
            <span className="terms-os-use-page-text144">
              12. SOME PARTICULARITIES OF SEPARATE SERVICES PROVIDING
            </span>
            <div className="terms-os-use-page-container26"></div>
            <span className="terms-os-use-page-text145">
              <span>
                12.1. Phone number providing. Phone number providing, as well as
                automatic prolongation of use of phone number, are possible if
                there are enough funds on your account balance.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                12.2. Insufficient funds. If there are not enough funds on your
                account balance, the phone number will be blocked. If during 7
                days payment is not made, you will lose the number.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                12.3. Restoring of blocked phone number. Restoring of blocked
                phone number is made not from the payment date, but from the
                date of number disconnection.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                12.4. Ban on regular change of number. Temporary use of active
                phone numbers (with regular replacement) is prohibited. In case,
                if phone numbers are used by you as temporary numbers and are
                regularly changed, DelaySoft OÜ has the right to stop providing
                services due to given Agreement without notification. In order
                to avoid the temporary use of numbers, the minimum connection
                period can be increased to 3, 6 or 12 months.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                12.5. Incoming SMS on DID numbers. In order to avoid abuse, on 1
                number is provided up to 500 free SMS per month.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                12.6. Routing of external traffic. Routing of traffic from the
                telephone network to TapRinger app network is prohibited, as any
                other reselling of services without agreement with the sales
                department. In case of violation, the account will be blocked
                and all outgoing calls will be recalculated in accordance with a
                retail tariff of telephone networks.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                12.7. Number confirmation for outgoing calls. By confirming a
                number and setting it as CallerID (calling party’s number), a
                client confirms that the owner of this number has no objections
                to its use as CallerID in TapRinger app service and that it does
                not contradict the telecommunications legislation of the country
                that issued the number and the agreement with the operator-owner
                of the number source.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                12.8. Outgoing calls and SMS from virtual numbers. All outgoing
                calls and SMS from virtual numbers connected with TapRinger app
                services have to be routed through TapRinger app network. It is
                prohibited to direct outgoing calls and SMS messages through
                other networks without a prior agreement with DelaySoft OÜ.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                Planting a flag &quot;I agree with the Terms of Use&quot; and/or
                keep using SIP-telephony services, you confirm that you got
                acquainted with given Terms of Use and give absolute agreement
                on fulfilment of conditions and provisions of the given treaty
                and provide DelaySoft OÜ with rights, worded in the given
                treaty.
              </span>
            </span>
          </div>
        </div>
      </main>
      <Footer></Footer>
      <Copyright></Copyright>
    </div>
  )
}

export default TermsOsUsePage

import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import FeatureCard from '../components/feature-card'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>TapRinger VoIP softphone</title>
        <meta
          name="description"
          content="TapRinger is the free softphone to make VoIP calls, messaging and buy phone numbers from 100 countries at lowest rates. Available for iPhone and Android."
        />
        <meta property="og:title" content="TapRinger VoIP softphone" />
        <meta
          property="og:description"
          content="TapRinger is the free softphone to make VoIP calls, messaging and buy phone numbers from 100 countries at lowest rates. Available for iPhone and Android."
        />
      </Helmet>
      <Navbar
        image_src="4ac0af6d-f893-4673-b691-c2925d3c1300"
        image_src1="4ac0af6d-f893-4673-b691-c2925d3c1300"
      ></Navbar>
      <main className="home-main">
        <div className="section-container">
          <div className="home-max-width max-content-container">
            <div className="home-content-container">
              <h2 className="home-text heading2">
                Save up to 90% on international and domestic call charges
              </h2>
              <div className="home-container1">
                <a
                  href="https://apps.apple.com/app/tapringer-voip-soft-phone/id1614151846"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link"
                >
                  <img
                    alt="image"
                    src="/playground_assets/apple_dark%402x.svg"
                    className="home-image"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.delaysoft.android.tapringer.app.client"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link1"
                >
                  <img
                    alt="image"
                    src="/playground_assets/google_dark%402x.svg"
                    className="home-image1"
                  />
                </a>
              </div>
            </div>
            <div className="home-image-container">
              <img
                alt="image"
                src="/playground_assets/phone_eng%403x1-1200w.png"
                className="home-image2"
              />
            </div>
          </div>
        </div>
        <div id="featuresanchor" className="home-section-one section-container">
          <div className="home-max-width1 max-content-container">
            <h2 className="home-text01 heading2">
              <span className="home-text02">TapRinger</span>
              <span> as convenient as using a regular phone</span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </h2>
            <div className="home-cards-container">
              <FeatureCard
                text="No international roaming. Flexible call rates and budget-friendly prices (per-second billing, special rates on domestic and international calls)."
                text1=" "
                heading="Cheap prices"
                image_src="/playground_assets/stars-200h.png"
              ></FeatureCard>
              <FeatureCard
                text="You can open a virtual office anywhere in the world. Clients and partners will call you on their local phone number, while you can be based anywhere in the world."
                text1=" "
                heading="Virtual office"
                image_src="/playground_assets/vector%203-200h.png"
              ></FeatureCard>
              <FeatureCard
                text="Large selection and geographical coverage of virtual phone numbers. More than 30,000 phone numbers in 100 countries are available for purchase and immediate connection."
                text1=" "
                heading="Virtual phone numbers"
                image_src="/playground_assets/vector%203%20%5B3%5D-200h.png"
              ></FeatureCard>
              <FeatureCard
                text="TapRinger allows you to send SMS messages to any mobile number in the world, and to receive the messages on our virtual phone numbers."
                text1=" "
                heading="Sending and receiving SMS messages"
                image_src="/playground_assets/mail-200h.png"
              ></FeatureCard>
            </div>
          </div>
        </div>
        <div className="section-container">
          <div className="home-max-width2 max-content-container">
            <div className="home-image-container1">
              <img
                alt="image"
                src="/playground_assets/section-image_tr-1200w.png"
                className="home-image3"
              />
            </div>
            <div className="home-content-container1">
              <h1 className="home-text06 heading2">
                <span className="home-text07">
                  VoIP is easy to use with
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text08">TapRinger</span>
              </h1>
              <span className="home-text09 content-Light">
                <span>
                  Voice over IP technology that blends the idea of a
                  conventional telephone with the speed and convenience of the
                  internet. The idea is simple - your voice is converted into
                  compressed data packets. This data is transmitted to the
                  addressee over a network, and decoded back to speech. This
                  significantly reduces the load on the network which, in turn,
                  reduces the cost of the call.
                </span>
              </span>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
      <Copyright></Copyright>
    </div>
  )
}

export default Home
